<template>
  <div>
    <div class="grid" :class="`grid-${images.length}`">
      <div v-for="(img,idx) in images" :style="imgStyle(img)" class="img" :key="'img-'+idx" @click="clickImg(idx)"></div>
    </div>

    <!-- 오버레이 -->
    <div class="overlay-image-container" v-if="vOverlay" @click="vOverlay=false">
      <div class="overlay-image-wrapper" @click.stop.prevent>
        <div class="ti-arrow-left" v-if="index > 0" @click="clickLeft">
          <img src="/static/icon/fi_chevron-left.svg" class="svg-white">
        </div>
        <div class="ti-arrow-right" v-if="index < images.length-1" @click="clickRight">
          <img src="/static/icon/fi_chevron-right.svg" class="svg-white">
        </div>
        <div class="overlay-close" @click="vOverlay=false">
          <img src="/static/icon/fi_close.svg" class="svg-sub4">
        </div>
        <div class="preview-wrapper">
          <div class="preview">
            <img :src="images[index]">
          </div>
          <div class="preview-counter">
            <img src="/static/icon/u_image.svg" class="svg-gray">
            <div style="margin-left:8px">{{ `${index+1}/${images.length}` }}</div>
          </div>
          <div class="preview-download-wrapper">
            <img src="/static/icon/u_arrow-to-bottom.svg" class="download-icon" @click="$refs.downloadModal.open">
          </div>
        </div>
      </div>
    </div>
    <div class="download-modal">
      <sweet-modal overlay-theme="dark" ref="downloadModal" width="564px"
                   :hide-close-button="true">
        <div class="subtitle4">파일을 다운로드하시겠습니까?</div>
        <div class="flex-between body5-medium" style="padding:64px 16px 0;">
          <button class="button body2-bold" style="width:50%;height:52px" @click="$refs.downloadModal.close()">취소</button>
          <button class="button is-primary margin-left-16 body2-bold"
                  style="width:50%;height:52px;"
                  @click="clickDownload">확인</button>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue'

  export default {
    name: "TalkImages",
    components: {
      SweetModal
    },
    props: {
      images: {
        type: Array
      }
    },
    created() {

    },
    data() {
      return {
        index: 0,
        vOverlay: false
      }
    },
    methods: {
      imgStyle(img) {
        return {
          width: '100%',
          height: '100%',
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      },
      clickDownload() {
        const a = document.createElement("a");
        a.href = this.images[this.index];
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);
        this.$refs.downloadModal.close();


        /*delete axios.defaults.headers.common.Authorization;

        axios({
          method: 'GET',
          url: this.images[this.index],
          responseType: 'blob'
        }).then(res=>{
          const blob = res.data;
          let file = this.images[this.index].split('/');
          file = file[file.length-1].split('.');
          const name = file[0];
          const ext = file[1];
          if (window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob((blob), `${name}.${ext}`);
          } else { // Others
            const reader = new FileReader();
            reader.onloadend = () => {
              const a = document.createElement("a");
              a.href = reader.result;
              a.style.display = 'none';
              a.setAttribute('download', `${name}.${ext}`);
              document.body.appendChild(a);
              a.click();
              a.parentNode.removeChild(a);
              this.$refs.downloadModal.close();
            }
            reader.readAsDataURL(blob);
          }
        });*/
      },
      clickImg(idx) {
        this.index = idx;
        this.vOverlay = true;
      },
      clickLeft() {
        if(this.index > 0) {
          this.index--;
        }
      },
      clickRight() {
        if(this.index < this.images.length-1) {
          this.index++;
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .grid
    display grid
    grid-template-columns repeat(6, 55px)
    grid-gap 4px
    border-radius 8px
    overflow hidden

  .grid-1
  .grid-2
  .grid-3
    grid-template-rows 120px
  .grid-4
  .grid-5
  .grid-6
    grid-template-rows 100px 100px

  .grid-1
    .img
      grid-column-start 1
      grid-column-end 7
  .grid-3
    .img:nth-child(1)
      grid-column-start 1
      grid-column-end 3
    .img:nth-child(2)
      grid-column-start 3
      grid-column-end 5
    .img:nth-child(3)
      grid-column-start 5
      grid-column-end 7

  .grid-2
  .grid-4
    .img:nth-child(odd)
      grid-column-start 1
      grid-column-end 4
    .img:nth-child(even)
      grid-column-start 4
      grid-column-end 7
  .grid-5
    .img:nth-child(1)
      grid-column-start 1
      grid-column-end 3
    .img:nth-child(2)
      grid-column-start 3
      grid-column-end 5
    .img:nth-child(3)
      grid-column-start 5
      grid-column-end 7
    .img:nth-child(4)
      grid-column-start 1
      grid-column-end 4
    .img:nth-child(5)
      grid-column-start 4
      grid-column-end 7

  .overlay-image-container
    position fixed
    z-index 1000
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba(0,0,0,0.5)

  .overlay-image-wrapper
    position fixed
    top 50%
    left 50%
    transform translate(-50%, -50%)

  .ti-arrow-left
  .ti-arrow-right
  .overlay-close
    position absolute
    cursor pointer

  .ti-arrow-left
    left -60px
  .ti-arrow-right
    right -60px

  .ti-arrow-left
  .ti-arrow-right
    top calc(50% - 24px)
    padding 8px
    img
      width 32px
      height 32px

  .overlay-close
    top 0
    right -60px
    background-color white
    width 32px
    height 32px
    border-radius 100%
    display flex
    align-items center
    justify-content center
    img
      width 18px
      height 18px

  .preview-wrapper
    border-radius 8px
    overflow hidden
    background white
    width 1098px
    height 654px

  .preview
    width 100%
    background $gray3
    height calc(100% - 64px)
    display flex
    justify-content center
    align-items center
    img
      max-height 100%

  .preview-counter
    position absolute
    bottom 80px
    left 50%
    transform translateX(-50%)
    background-color rgba(0,0,0,0.5)
    border-radius 30px
    padding 4px 12px
    color white
    display flex
    align-items flex-end
    font-size 16px
    img
      width 20px

  .preview-download-wrapper
    background-color white
    display flex
    align-items center
    justify-content flex-end
    height 64px
    padding 0 20px
  .download-icon
    filter invert(29%) sepia(0%) saturate(1%) hue-rotate(352deg) brightness(99%) contrast(88%)
    cursor pointer
  .download-icon:hover
    filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)


  @media (max-width:500px)
    .grid
      grid-template-columns repeat(6, 32px)
    .preview
      height calc(100% - 40px)

    .preview-wrapper
      width 250px
      height 200px
    .preview-download-wrapper
      height 40px
      img
        width 20px
    .overlay-close
      right -38px
      width 24px
      height 24px
      img
        width 16px
        height 16px
    .preview-counter
      bottom 48px
      font-size 11px
      img
        width 15px

    .ti-arrow-left
      left -50px
    .ti-arrow-right
      right -50px
</style>
<style lang="stylus">
  .download-modal .sweet-modal.is-mobile-fullscreen
    top 50% !important
    left 50% !important
    transform translate(-50%, -50%) !important

  .download-modal .sweet-content
    padding-bottom 12px !important

  @media (min-width: 501px)
    .download-modal .sweet-modal
      height 250px
    .download-modal .sweet-content
      padding-top 72px !important

  @media (max-width: 500px)
    .download-modal .sweet-modal
      max-width 80vw !important
      height 200px
      .subtitle4
        font-size 14px
      .body2-bold
        font-size 13px
      .button
        height 36px !important

    .download-modal .sweet-content
      padding-top 58px !important
</style>
